import { Fragment, useMemo } from "react"
import styled from "styled-components"

import { useMediaQuery } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import { FireCOAlarmButton } from "src/components/Homes/HomeDetails/FireCOAlarm/FireCOAlarmButton"
import { IndoorClimateButton } from "src/components/Homes/HomeDetails/IndoorClimate/IndoorClimateButton"
import { NoiseMonitoringButton } from "src/components/Homes/HomeDetails/NoiseMonitoring/NoiseMonitoringButton"
import { OccupancyButton } from "src/components/Homes/HomeDetails/Occupancy/OccupancyButton"
import { SecurityAlarmButton } from "src/components/Homes/HomeDetails/SecurityAlarm/SecurityAlarmButton"
import { SmokingDetectionButton } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionButton"
import { breakpoint } from "src/constants/breakpoints"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { monitoringAvailable } from "src/data/homes/logic/homeUtil"
import { AlarmMode, AlarmStatus, IAlarm } from "src/data/homes/types/alarmTypes"
import {
  IHome,
  MonitoringType,
  TDisturbanceMonitoringActive,
} from "src/data/homes/types/homeTypes"
import { TOrganizationRole } from "src/data/organizations/types/organizationMemberTypes"
import {
  getAccessLogic,
  toggleFireCoAlarmAllowed,
  toggleNoiseMonitoringAllowed,
  toggleSecurityAlarmAllowed,
  toggleSmokingDetectionAllowed,
} from "src/data/user/logic/accessLogic"
import { usePermissions } from "src/data/user/logic/usePermissions"
import { AccessLevelRank } from "src/data/user/types/accessTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { divider } from "src/ui/colors"
import { GridTable } from "src/ui/GridTable/GridTable"
import { InternalLink } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"

export function HomeTable({
  homes,
  devices = [],
  loading = false,
}: {
  homes: IHome[]
  devices: TDevice[]
  loading: boolean
}) {
  const { t, langKeys } = useTranslate()
  const mobile = useMediaQuery(`(${breakpoint.normalDown})`)

  const permissions = usePermissions()
  const cigaretteSmokeAvailability = useFeatureAvailability({
    feature: Feature.CIGARETTE_SMOKE,
  })
  const { available: hasEurekaDevice } = useFeatureAvailability({
    feature: "eureka",
  })

  const indoorClimateAvailable = useFeatureAvailability({
    feature: "indoor_climate_monitoring",
  }).available

  const transformedHomes = useMemo(() => {
    return transformHomes(homes, devices)
  }, [homes, devices])

  /* eslint-disable react/jsx-key */
  const headers = [
    <div>{t(langKeys.home)}</div>,
    <div>{t(langKeys.sound_noise_monitoring)}</div>,
    <div>{t(langKeys.crowd_detect_title)}</div>,
    cigaretteSmokeAvailability.available ? (
      <div>{t(langKeys.smoking_detection_title)}</div>
    ) : (
      false
    ),
    hasEurekaDevice ? <div>{t(langKeys.fire_and_co_alarm_title)}</div> : false,
    indoorClimateAvailable ? <div>{t(langKeys.indoor_climate)}</div> : false,
    <div>{t(langKeys.home_security_alarm_title)}</div>,
    <div>{t(langKeys.sensors_online)}</div>,
  ].filter((h): h is JSX.Element => h !== false)
  /* eslint-enable react/jsx-key */

  const rows = transformedHomes.map((home) => {
    const {
      homeAccessRank,
      homeId,
      name,
      homeDevices,
      alarmMode,
      silentAlarm,
      alarmInfo,
      violations,
      detailedAlarmStatus,
      disturbanceMonitoringActive,
      online,
      occupancy,
      homeUserRole,
      noiseMonitoringState,
    } = home

    const access = getAccessLogic({ role: homeUserRole })
    const canViewHome =
      homeAccessRank >= AccessLevelRank.MEMBER || access.hasMemberAccess
    const canToggleSecurity =
      toggleSecurityAlarmAllowed(homeAccessRank) || access.hasMemberAccess
    const canToggleNoiseMonitoring =
      toggleNoiseMonitoringAllowed(homeAccessRank) || access.hasAdminAccess
    const canToggleOccupancy =
      homeAccessRank >= AccessLevelRank.MEMBER || access.hasAdminAccess
    const canToggleSmokingDetection =
      toggleSmokingDetectionAllowed(homeAccessRank) || access.hasAdminAccess
    const canToggleFireCoAlarm =
      toggleFireCoAlarmAllowed(homeAccessRank) || access.hasAdminAccess

    const canToggleIndoorClimate = permissions.indoorClimateModify

    if (mobile) {
      return (
        <MobileRow key={home.homeId}>
          {!canViewHome ? (
            <span>{name}</span>
          ) : (
            <InternalLink to={Routes.Home.location(homeId)}>
              {name}
            </InternalLink>
          )}
          <div>
            {monitoringAvailable({
              type: MonitoringType.SOUND,
              home,
              devices: homeDevices,
            }) && (
              <NoiseMonitoringButton
                homeId={homeId}
                violations={violations}
                isActive={!!disturbanceMonitoringActive}
                hideStatus
                toggleAllowed={canToggleNoiseMonitoring}
                monitoringState={noiseMonitoringState}
                graphicButton
                row
              />
            )}

            {monitoringAvailable({
              type: MonitoringType.OCCUPANCY,
              home,
              devices: homeDevices,
            }) && (
              <OccupancyButton
                occupancy={occupancy}
                homeId={homeId}
                violations={violations}
                toggleAllowed={canToggleOccupancy}
                graphicalButton
                hideStatus
              />
            )}

            {cigaretteSmokeAvailability.available &&
              monitoringAvailable({
                type: MonitoringType.CIGARETTE,
                home,
                devices: homeDevices,
              }) && (
                <SmokingDetectionButton
                  homeId={homeId}
                  violations={violations}
                  hasAccess={canToggleSmokingDetection}
                  smokingDetection={home.smokingDetection}
                  hideStatus
                />
              )}

            {hasEurekaDevice &&
              monitoringAvailable({
                type: MonitoringType.FIRECO,
                home,
                devices: homeDevices,
              }) && (
                <FireCOAlarmButton
                  homeState={home}
                  configureAllowed={canToggleFireCoAlarm}
                  hideStatus
                  graphicalButton
                />
              )}

            {indoorClimateAvailable &&
              monitoringAvailable({
                type: MonitoringType.INDOOR_CLIMATE,
                home,
                devices: homeDevices,
              }) && (
                <IndoorClimateButton
                  homeId={home.homeId}
                  indoorClimateMonitoring={home.indoor_climate_monitoring}
                  toggleAllowed={canToggleIndoorClimate}
                  hideStatus
                />
              )}

            {monitoringAvailable({
              type: MonitoringType.MOTION,
              home,
              devices: homeDevices,
            }) && (
              <SecurityAlarmButton
                homeId={homeId}
                violations={violations}
                showAlarmArmDelayHint={false}
                alarmStatus={detailedAlarmStatus}
                gracePeriodExpiresAt={alarmInfo.grace_period_expires_at}
                gracePeriodSecs={alarmInfo.grace_period_secs}
                alarmMode={alarmMode}
                toggleAllowed={canToggleSecurity}
                silentAlarm={silentAlarm}
                graphicalButton={true}
                hideStatus
                row
              />
            )}
          </div>
        </MobileRow>
      )
    }

    return (
      <Fragment key={homeId}>
        <div>
          {!canViewHome ? (
            name
          ) : (
            <InternalLink to={Routes.Home.location(homeId)}>
              {name}
            </InternalLink>
          )}
        </div>
        <StatusBox>
          {monitoringAvailable({
            type: MonitoringType.SOUND,
            home,
            devices: homeDevices,
          }) ? (
            <NoiseMonitoringButton
              homeId={homeId}
              monitoringState={noiseMonitoringState}
              violations={violations}
              isActive={!!disturbanceMonitoringActive}
              toggleAllowed={canToggleNoiseMonitoring}
              graphicButton
              row
              style={{ border: "none" }}
            />
          ) : (
            "-"
          )}
        </StatusBox>

        <StatusBox>
          {monitoringAvailable({
            type: MonitoringType.OCCUPANCY,
            home,
            devices: homeDevices,
          }) ? (
            <OccupancyButton
              occupancy={occupancy}
              homeId={homeId}
              violations={violations}
              toggleAllowed={canToggleOccupancy}
              graphicalButton
            />
          ) : (
            "-"
          )}
        </StatusBox>

        {cigaretteSmokeAvailability.available && (
          <StatusBox>
            {monitoringAvailable({
              type: MonitoringType.CIGARETTE,
              home,
              devices: homeDevices,
            }) ? (
              <SmokingDetectionButton
                homeId={homeId}
                violations={violations}
                hasAccess={canToggleSmokingDetection}
                smokingDetection={home.smokingDetection}
              />
            ) : (
              "-"
            )}
          </StatusBox>
        )}

        {hasEurekaDevice && (
          <StatusBox>
            {(home.fireCoAlarmAvailable && (
              <FireCOAlarmButton
                homeState={home}
                graphicalButton
                configureAllowed={canToggleFireCoAlarm}
              />
            )) ||
              "-"}
          </StatusBox>
        )}

        {indoorClimateAvailable && (
          <StatusBox>
            {monitoringAvailable({
              type: MonitoringType.INDOOR_CLIMATE,
              home,
              devices: homeDevices,
            }) ? (
              <IndoorClimateButton
                homeId={home.homeId}
                indoorClimateMonitoring={home.indoor_climate_monitoring}
                toggleAllowed={canToggleIndoorClimate}
              />
            ) : (
              "-"
            )}
          </StatusBox>
        )}

        <StatusBox>
          {monitoringAvailable({
            type: MonitoringType.MOTION,
            home,
            devices: homeDevices,
          }) ? (
            <SecurityAlarmButton
              homeId={homeId}
              violations={violations}
              showAlarmArmDelayHint={false}
              alarmStatus={detailedAlarmStatus}
              gracePeriodExpiresAt={alarmInfo.grace_period_expires_at}
              gracePeriodSecs={alarmInfo.grace_period_secs}
              alarmMode={alarmMode}
              silentAlarm={silentAlarm}
              toggleAllowed={canToggleSecurity}
              graphicalButton
              row
              style={{ border: "none" }}
            />
          ) : (
            "-"
          )}
        </StatusBox>

        <StatusBox>
          {online}/{homeDevices.length}
        </StatusBox>
      </Fragment>
    )
  })

  if (!homes || !cigaretteSmokeAvailability.ready) {
    return null
  }

  return (
    <TableBox>
      {mobile ? (
        loading ? (
          <MobileSkeleton />
        ) : (
          rows
        )
      ) : (
        <GridTable
          header={headers}
          rows={loading ? desktopSkeletonRows() : rows}
          templateColumns={`minmax(150px, 1fr) repeat(${
            headers.length - 1
          }, auto)`}
          useFallbackResponsiveMode
        />
      )}
    </TableBox>
  )
}

function MobileSkeleton() {
  const mobileSkeletonRow = () => (
    <MobileRow key={Math.random()}>
      <Skeleton
        variant="text"
        width={`${Math.floor(Math.random() * 40) + 60}%`}
      />
      <div>
        <Skeleton variant="rect" width="25px" />
        <Skeleton variant="rect" width="25px" />
        <Skeleton variant="rect" width="25px" />
      </div>
    </MobileRow>
  )

  return <>{Array.from(Array(4), () => mobileSkeletonRow())}</>
}

function desktopSkeletonRows() {
  const desktopSkeletonRow = () => (
    <Fragment key={Math.random()}>
      {/* //home */}
      <div>
        <Skeleton
          variant="text"
          width={`${Math.floor(Math.random() * 40) + 60}%`}
        />
      </div>
      {/* //security button */}
      <div>
        <Skeleton variant="rect" />
      </div>
      {/* //noise monitoring */}
      <div>
        <Skeleton variant="rect" />
      </div>
      {/* //crowd detect */}
      <div>
        <Skeleton variant="rect" />
      </div>
      {/* //sensors online */}
      <div>
        <Skeleton variant="rect" />
      </div>
      {/* //sensors offline */}
      <div>
        <Skeleton variant="rect" />
      </div>
    </Fragment>
  )

  return Array.from(Array(4), () => desktopSkeletonRow())
}

function transformHomes(homes: IHome[] = [], devices: TDevice[] = []) {
  if (!homes) {
    return []
  }

  return homes.map((home) => {
    const homeDevices = devices.filter((d) => d.home === home.home_id)
    const offlineHomeDevices = homeDevices.filter((d) => d.offline)

    const offline = offlineHomeDevices.length || 0

    const homeAccessRank = AccessLevelRank.RESPONDER
    let alarmInfo: IAlarm
    let alarmMode: AlarmMode | undefined
    let silentAlarm: boolean
    let detailedAlarmStatus: AlarmStatus | undefined
    let disturbanceMonitoringActive: TDisturbanceMonitoringActive
    let homeUserRole: TOrganizationRole = "responder"

    /* eslint-disable prefer-const */
    alarmInfo = { ...home.alarm }
    alarmMode = home.alarm?.alarm_mode
    silentAlarm = !!home.alarm?.silent_alarm
    detailedAlarmStatus = home.alarm?.alarm_status
    disturbanceMonitoringActive =
      !!home.disturbance_monitoring?.disturbance_monitoring_active
    homeUserRole = home.user_role
    const noiseMonitoringState = home.disturbance_monitoring?.state_v2
    /* eslint-enable prefer-const */

    return {
      ...home,
      name: home.name,
      homeId: home.home_id,
      occupancy: home.occupancy,
      violations: home.violations,

      alarmMode,
      silentAlarm,
      alarmInfo,
      detailedAlarmStatus,
      disturbanceMonitoringActive,
      noiseMonitoringState,

      homeDevices,
      online: homeDevices.length - offline,
      offline,
      homeAccessRank,
      homeUserRole,
      smokingDetection: home.smoking_detection,
      fireCoAlarmAvailable: Boolean(
        home.fire_alarm?.available || home.carbon_monoxide_alarm?.available
      ),
    }
  })
}

const TableBox = styled.div`
  width: 100%;
  overflow-x: auto;
`

const MobileRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: ${spacing.L};
  column-gap: ${spacing.S};
  border-bottom: 1px solid ${divider};
  padding-bottom: ${spacing.M};
  margin-bottom: ${spacing.M};
  > :first-child {
    flex: 1 1 20ch;
  }
  > :last-child {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    gap: ${spacing.S};
    button {
      margin: 0;
      padding: 0;
    }
  }
`

const StatusBox = styled.div`
  align-items: center;
`
