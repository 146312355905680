import styled from "styled-components"

import { DashboardWidgets } from "src/components/Dashboard/DashboardWidgets/DashboardWidgets"
import { NoHomes, NoHomesOnFilter } from "src/components/EmptyState/NoHomes"
import { FilterQueryBox } from "src/components/Filter/FilterQueryBox"
import { HomeTable } from "src/components/Homes/HomeList/HomeTable"
import { Pager } from "src/components/Pager/Pager"
import { LinearProgressBar } from "src/components/ProgressBar/LinearProgressBar"
import { DEVICE_LIST_LIMIT_MAX } from "src/data/devices/logic/deviceConstants"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { useIsHomeGroupAdmin } from "src/data/homeGroups/logic/useIsHomeGroupAdmin"
import { useUrlHomeFilters } from "src/data/homes/hooks/useUrlParamHome"
import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { useFetchHomeTokens } from "src/data/homes/queries/homeTokenQueries"
import { IHome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchHomes,
  useFetchHomesCount,
} from "src/data/organizations/queries/homeQueries"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useFlags } from "src/hooks/useFlags"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { brandText } from "src/ui/colors"
import HomeIcon from "src/ui/icons/home.svg"
import DeviceIcon from "src/ui/icons/sensor.svg"
import { MainView } from "src/ui/Layout/MainView"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { unique } from "src/utils/genericUtil"

export function HomeList() {
  const { org } = useOrganization()
  const orgId = org.id
  const { navigate } = useRouter()

  const { t } = useTranslate()
  const access = getAccessLogic({ role: org.user_role })
  const isHomegroupAdmin = useIsHomeGroupAdmin()
  const canAddHomeAccess = access.hasAdminAccess || isHomegroupAdmin.value

  const { homeListWidgets } = useFlags()

  const { setOffset, filters, searchFieldFilter, dropdownFilters } =
    useUrlHomeFilters()

  const fetchHomes = useFetchHomes({
    orgId,
    filters,
  })
  const fetchHomesCount = useFetchHomesCount({
    orgId: org.id,
    options: { enabled: access.hasAdminAccess },
  })
  const fetchHomeTokens = useFetchHomeTokens({
    orgId,
    filters: { activated: false },
    options: { enabled: access.hasAdminAccess },
  })

  const homes: IHome[] = fetchHomes.data?.homes || []
  const paging = fetchHomes.data?.paging
  const totalHomesCount = fetchHomesCount.data || 0
  const unusedHomeTokens = fetchHomeTokens.data?.paging.total_count ?? 0
  const homeTokensProgress = unusedHomeTokens
    ? (100 * totalHomesCount) / (unusedHomeTokens + totalHomesCount)
    : 100

  const fetchDevices = useFetchDevices({
    orgId,
    filter: {
      home_ids: unique(fetchHomes.data?.homes.map((h) => h.home_id)),
      limit: DEVICE_LIST_LIMIT_MAX,
    },
    options: { enabled: !!fetchHomes.data?.homes.length },
  })

  const {
    crowd_detected,
    fire_or_co_alarm_ongoing_filter,
    low_battery,
    name,
    noise_ongoing,
    offline_sensors,
    smoking_detected,
    indoor_climate_ongoing_alert_filter,
  } = filters

  const nonPagingFiltersApplied =
    fire_or_co_alarm_ongoing_filter ||
    noise_ongoing ||
    name ||
    crowd_detected ||
    smoking_detected ||
    offline_sensors ||
    low_battery ||
    indoor_climate_ongoing_alert_filter

  const showNoHomes =
    fetchHomes.isFetched &&
    !homes.length &&
    filters.offset === 0 &&
    !nonPagingFiltersApplied

  const showFilterEmpty =
    !fetchHomes.isFetching &&
    fetchHomes.isFetched &&
    !homes.length &&
    nonPagingFiltersApplied

  const showHomeTokenProgress =
    !fetchHomesCount.isInitialLoading &&
    access.hasAdminAccess &&
    unusedHomeTokens > 0

  return (
    <MainView
      title={t(langKeys.home, { count: 2 })}
      titleBarProps={{
        bottomMargin: homeListWidgets || canAddHomeAccess ? "small" : undefined,
        showBottomBorder: false,
        actionBar: canAddHomeAccess && (
          <ActionMenu>
            <MButton onClick={() => navigate(Routes.CreateHome.location())}>
              {t(langKeys.home_create_new_home)}
            </MButton>
          </ActionMenu>
        ),
        description: homeListWidgets && (
          <Description
            homeCount={fetchHomes.data?.paging.total_count}
            sensorCount={fetchDevices?.data?.paging.total_count}
          />
        ),
      }}
      size="unset"
    >
      {showHomeTokenProgress && (
        <Header>
          <Counter>
            <CounterSection>
              <MText variant="bodyS">{totalHomesCount}</MText>
              <MText variant="bodyS" color="secondary">
                {t(langKeys.homes_created, { count: totalHomesCount })}
              </MText>
            </CounterSection>
            <CounterSection>
              <MText variant="heading3">{unusedHomeTokens}</MText>
              <MText variant="bodyS" color="secondary">
                {t(langKeys.homes_to_create, { count: unusedHomeTokens })}
              </MText>
            </CounterSection>
          </Counter>
          <LinearProgressBar
            segments={[{ value: homeTokensProgress, type: "good" }]}
          />
        </Header>
      )}

      <section>
        {homeListWidgets && <DashboardWidgets />}

        <FilterQueryBox
          dropdownFilters={dropdownFilters}
          searchFieldFilter={searchFieldFilter}
        />

        <HomeTable
          homes={homes}
          devices={fetchDevices.data?.devices || []}
          loading={
            fetchHomes.isLoading ||
            !isHomegroupAdmin.ready ||
            fetchDevices.isInitialLoading // Since this is lazy query we need to look at isInitialLoading
          }
        />

        {showNoHomes && !fetchHomes.isLoading && (
          <NoHomes body={t(langKeys.homes_list_empty_state_body)} />
        )}

        {showFilterEmpty && <NoHomesOnFilter />}

        {!showNoHomes && !showFilterEmpty && (
          <PagerWrapper>
            <Pager
              limit={filters.limit ?? HOME_LIST_LIMIT}
              offset={filters.offset ?? 0}
              setOffset={setOffset}
              totalCount={paging?.total_count}
            />
          </PagerWrapper>
        )}
      </section>
    </MainView>
  )
}

function Description({
  homeCount,
  sensorCount,
}: {
  homeCount: number | undefined
  sensorCount: number | undefined
}) {
  const { t, langKeys } = useTranslate()

  return (
    <DescriptionBox>
      <HomeIcon width={18} height={18} color={brandText} />
      {homeCount ?? "-"} {t(langKeys.home, { count: homeCount })}
      <DeviceIcon width={18} height={18} color={brandText} />
      {sensorCount ?? "-"} {t(langKeys.sensor, { count: sensorCount })}
    </DescriptionBox>
  )
}

const DescriptionBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`

const ActionMenu = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: ${spacing.XS};
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.XL2};
  max-width: 800px;
`

const Counter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.XS};
`

const CounterSection = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacing.XS2};
`
