import { useUrlPager } from "src/components/Pager/useUrlPager"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import {
  CrowdsFilter,
  FireCOAlarmFilter,
  homeDropdownFilters,
  IndoorClimateFilter,
  LowBatteryFilter,
  NoSensorFilter,
  OnGoingNoiseEventFilter,
  SmokingDetectionFilter,
  TextHomeFilter,
  UnmonitoredHomesFilter,
} from "src/data/filters/filtersHome"
import {
  IHomeFilterBody,
  OfflineSensorsHomeFilter,
} from "src/data/homes/types/homeQueryTypes"
import { useUrlParam, useUrlParamBoolean } from "src/hooks/useUrlParam"

type IHomeFilterKeys = keyof IHomeFilterBody

const EXCLUSIVE_FILTERS_IDS = homeDropdownFilters
  .filter((f) => !!f.exclusive)
  .map((f) => f.id)

export function useUrlHomeFilters() {
  const cigaretteSmokeAvailability = useFeatureAvailability({
    feature: Feature.CIGARETTE_SMOKE,
  })
  const { available: hasEurekaDevice } = useFeatureAvailability({
    feature: "eureka",
  })

  const indoorClimateAvailable = useFeatureAvailability({
    feature: "indoor_climate_monitoring",
  }).available

  const { limit, offset, setOffset, replaceOffset, setLimit } = useUrlPager()

  const { value: name } = useUrlParam(TextHomeFilter.id)
  const { value: noise_ongoing } = useUrlParamBoolean(
    OnGoingNoiseEventFilter.id
  )
  const { value: crowd_detected } = useUrlParamBoolean(CrowdsFilter.id)
  const { value: smoking_detected } = useUrlParamBoolean(
    SmokingDetectionFilter.id
  )
  const { value: no_sensors } = useUrlParamBoolean(NoSensorFilter.id)
  const { value: low_battery } = useUrlParamBoolean(LowBatteryFilter.id)
  const { value: offline_sensors } = useUrlParam<OfflineSensorsHomeFilter>(
    UnmonitoredHomesFilter.id
  )
  const { value: fire_or_co_alarm_ongoing_filter } = useUrlParamBoolean(
    FireCOAlarmFilter.id
  )

  const { value: indoor_climate_ongoing_alert_filter } = useUrlParamBoolean(
    IndoorClimateFilter.id
  )

  const filterBodyBase: IHomeFilterBody = { name, limit, offset }
  const filterBodyDropdown: IHomeFilterBody = {
    noise_ongoing,
    crowd_detected,
    smoking_detected,
    offline_sensors,
    no_sensors,
    low_battery,
    fire_or_co_alarm_ongoing_filter,
    indoor_climate_ongoing_alert_filter,
  }
  const filterBody = { ...filterBodyBase, ...filterBodyDropdown }

  const dropdownFiltersApplied = (
    Object.entries(filterBodyDropdown) as [IHomeFilterKeys, boolean | number][]
  ).filter(([filterId, value]) => Boolean(value))

  const exclusiveFilterApplied = !!dropdownFiltersApplied.find(
    ([filterId, value]) => Boolean(EXCLUSIVE_FILTERS_IDS.includes(filterId))
  )

  const dropdownFilters = homeDropdownFilters
    .map((f) => {
      if (exclusiveFilterApplied) {
        // disable adding filters when an exclusive filter is active:
        return { ...f, disabled: true }
      }

      if (dropdownFiltersApplied.length && f.exclusive) {
        // disable adding exclusive filters when any other filter is active:
        return { ...f, disabled: true }
      }

      return f
    })
    .filter((filter) => {
      if (
        !cigaretteSmokeAvailability.available &&
        filter.id === SmokingDetectionFilter.id
      ) {
        return false
      }

      if (!hasEurekaDevice && filter.id === FireCOAlarmFilter.id) {
        return false
      }

      if (!indoorClimateAvailable && filter.id === IndoorClimateFilter.id) {
        return false
      }

      return true
    })

  return {
    filters: filterBody,
    setLimit,
    setOffset,
    replaceOffset,
    searchFieldFilter: TextHomeFilter,
    dropdownFilters,
  }
}
