import { SvgIconProps } from "@material-ui/core"

import { TIndoorClimateMonitoring } from "src/data/homes/types/homeTypes"
import { colorScale, colorsV2 } from "src/ui/colors"
import IndoorClimateFilledIcon from "src/ui/icons/indoor-climate-filled.svg"

export function IndoorClimateIcon({
  state,
  status,
  iconProps,
}: {
  state: TIndoorClimateMonitoring["state"] | undefined
  status?: TIndoorClimateMonitoring["status"]
  iconProps?: SvgIconProps
}) {
  if (state === "enabled") {
    if (status === "alerting") {
      return (
        <IndoorClimateFilledIcon
          fill={colorsV2.systemErrorLight}
          color={colorsV2.systemErrorDark}
          {...iconProps}
        />
      )
    }

    return (
      <IndoorClimateFilledIcon
        fill={colorsV2.systemInfoLight}
        color={colorsV2.systemInfoDark}
        {...iconProps}
      />
    )
  }

  return (
    <IndoorClimateFilledIcon
      fill={colorsV2.neutralDark}
      color={colorScale.koti[400]}
      {...iconProps}
    />
  )
}
